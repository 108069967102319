import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Switch,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";

const ViewUser = ({ open, handleClose, data }) => {
    const theme1 = useTheme();
    const colors = tokens(theme1.palette.mode);
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const initialValues = {
        username: data?.username || '',
        email: data?.email || '',
        phone_number: data?.phone_number || '',
        note: data?.note || '',
        isAdmin: data?.role === 'Admin',
    };

    return (
        <Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        maxWidth: '1200px',
                        width: '1100px',
                        height: '400px',
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: colors.addDialog[100], color: colors.addDialog[400] }}>
                    <DialogTitle id="responsive-dialog-title"
                                 sx={{ fontWeight: "600", fontSize: "18px" }}>{t('view_user')}</DialogTitle>
                    <CloseIcon sx={{ margin: '15px 15px 0 0', cursor: 'pointer', fontWeight: "600", fontSize: "26px", color: colors.addDialog[400] }}
                               onClick={handleClose} />
                </Box>
                <DialogContent>
                    <Box
                        display="grid"
                        gap="20px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            paddingX: '7px',
                            '& > div': { gridColumn: isNonMobile ? undefined : 'span 2' },
                        }}
                    >
                        <TextField
                            label={t('name')}
                            name="username"
                            sx={{ gridColumn: 'span 2' }}
                            value={initialValues.username}

                            InputProps={{
                                readOnly: true,
                                sx: {
                                    backgroundColor: colors.addDialog[200],
                                    color: colors.addDialog[300],
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: colors.addDialog[400],
                                },
                            }}
                        />
                        <TextField
                            label={t('email')}
                            name="email"
                            sx={{ gridColumn: 'span 2' }}
                            value={initialValues.email}

                            InputProps={{
                                readOnly: true,
                                sx: {
                                    backgroundColor: colors.addDialog[200],
                                    color: colors.addDialog[300],
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: colors.addDialog[400],
                                },
                            }}
                        />
                        <TextField
                            label={t('phone_number')}
                            name="phone_number"
                            sx={{ gridColumn: 'span 2' }}
                            value={initialValues.phone_number}

                            InputProps={{
                                readOnly: true,
                                sx: {
                                    backgroundColor: colors.addDialog[200],
                                    color: colors.addDialog[300],
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: colors.addDialog[400],
                                },
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    name="isAdmin"
                                    checked={initialValues.isAdmin}
                                    readOnly
                                />
                            }
                            label={t('isAdmin')}
                            sx={{ gridColumn: 'span 2' }}
                        />
                        <TextField
                            label={t('note')}
                            name="note"
                            sx={{ gridColumn: 'span 2' }}
                            value={initialValues.note}
                            
                            InputProps={{
                                readOnly :true,
                                sx: {
                                    backgroundColor: colors.addDialog[200],
                                    color: colors.addDialog[300],
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: colors.addDialog[400],
                                },
                            }}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ViewUser;
