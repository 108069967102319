import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Switch,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {tokens} from "../../theme";

const ViewGateway = ({open, handleClose,data}) => {
    const theme1 = useTheme();
    const colors = tokens(theme1.palette.mode);
    const {t} = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const initialValues = {
        name: data?.name || '',
        realm: data?.realm || '',
        register: data?.register || false,
        username: data?.username || '',
        password: data?.password || '',
        caller_id_number: data?.caller_id_number || '',
        transport: data?.transport || '',
        channel_count: data?.channel_count || '',
    };

    return (
        <Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        maxWidth: '1200px',
                        width: '1100px',
                        height: '550px',
                    },
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: colors.addDialog[100], color:colors.addDialog[400]}}>
                    <DialogTitle id="responsive-dialog-title"
                                 sx={{fontWeight: "600", fontSize: "18px"}}>{t('view_gateway')}</DialogTitle>
                    <CloseIcon sx={{margin: '15px 15px 0 0', cursor: 'pointer', fontWeight: "600", fontSize: "26px",color:colors.addDialog[400]}}
                               onClick={handleClose}/>
                </Box>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                    >
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        paddingX: '7px',
                                        '& > div': {gridColumn: isNonMobile ? undefined : 'span 2'},
                                    }}
                                >
                                    <TextField
                                        label={t('name')}
                                        name="name"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.name}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('realm')}
                                        name="realm"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.realm}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="register"
                                                checked={values.register}
                                                inputProps={{readOnly: true}}
                                            />
                                        }
                                        label={t('register')}
                                        sx={{gridColumn: 'span 2'}}
                                    />
                                    <TextField
                                        label={t('username')}
                                        name="username"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.username}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('password')}
                                        name="password"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.password}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('caller_id_number')}
                                        name="caller_id_number"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.caller_id_number}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />

                                    <TextField
                                        label={t('transport')}
                                        name="transport"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.transport}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('channel_count')}
                                        name="channel_count"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.channel_count}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                            readOnly: true
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ViewGateway;
