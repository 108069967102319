import {Box, useTheme, TextField, Button, MenuItem, Select} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import CustomToolbar from "../../components/CustomToolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

dayjs.extend(isBetween);

const Requests = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const isMaxWidth1280 = useMediaQuery("(max-width:1280px)");
    const isMaxWidth1200 = useMediaQuery("(max-width:1200px)");
    const isMinWidth1140 = useMediaQuery("(min-width:1023px)");
    const fontSize = isMaxWidth1280 && isMinWidth1140 ? '14px' : '16px';
    const [data, setData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_GET_ALL_CALLS_BY_USER_ID_API_ENDPOINT;
    const changeTheCallStatusEncpoint = process.env.REACT_APP_CHANGE_THE_CALL_STATUS_API_ENDPOINT;
    const [callResultOptions, setCallResultOptions] = useState([]);
    const [filter, setFilter] = useState({
        id: '',
        phone_number: '',
        otp: '',
        trans_id: '',
        call_result: '',
        startDate: '',
        endDate: '',
    });

    useEffect(() => {
        const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        setFilter(prevFilter => ({
            ...prevFilter,
            startDate: startOfMonth,
            endDate: today
        }));
    }, []);

    const fetchFilteredData = async () => {
        const token = Cookie.get("token");
        const cookieToken = Cookie.get("token");
        const decodedToken = jwtDecode(cookieToken);
        const user_id = decodedToken.user_id;
        const { id, phone_number, otp,trans_id, call_result, startDate, endDate } = filter;
        const from_date = startDate ? new Date(startDate).toISOString().split('T')[0] : null;
        const to_date = endDate ? new Date(endDate).toISOString().split('T')[0] : null;
        try {
            const response = await axios.post(apiEndpoint, {
                user_id: user_id,
                call_id: id || null,
                phone_number: phone_number || null,
                otp: otp || null,
                trans_id: trans_id || null,
                call_result: call_result || null,
                from_at: from_date,
                to_at: to_date
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setData(response.data);
        } catch (error) {
        }
    };



    const fetchData = async () => {
        const token = Cookie.get("token");
        const cookieToken = Cookie.get("token");
        const decodedToken = jwtDecode(cookieToken);
        const user_id = decodedToken.user_id;
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        const from_date = new Date(currentYear, currentMonth, 1);
        const to_date = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);
        const formattedFromDate = from_date.toISOString().split('T')[0];  // yyyy-MM-dd formatında
        const formattedToDate = to_date.toISOString().split('T')[0];
        try {
            const response = await axios.post(apiEndpoint, {
                user_id: user_id,
                from_at: formattedFromDate,
                to_at: formattedToDate
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const results = [...new Set(data.map(item => item.call_result))];
        setCallResultOptions(results);
    }, [data]);
    const columns = [
        {
            field: "id", headerName: t('id'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "phone_number",
            headerName: t('phone_number'),
            type: "number",
            headerAlign: "left",
            align: "left",
            flex: 1,
            renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "otp", headerName: t('otp'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "trans_id", headerName: t('trans_id'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "call_result",
            headerName: t('status'),
            flex: 1,
            valueGetter: (params) => t(params.row.call_result),
            renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "created_at",
            headerName: t('date'),
            flex: 1,
            valueGetter: (params) => t(params.row.created_at),
            renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "try_count",
            headerName: t('try_count_call'),
            flex: 0.5,
            valueGetter: (params) => t(params.row.try_count),
            renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "actions",
            headerName: t('actions'),
            flex: 1.9,
            renderCell: (params) => (
                <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{
                            borderRadius: '8px',
                            textTransform: 'none',
                            color: 'white',
                            backgroundColor: 'green'
                        }}
                        onClick={() => changeTheCallStatus(params.row.id,'created')}
                    >
                        {t('call')}
                    </Button>

                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize, fontWeight: "bold"}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
    ];

    const changeTheCallStatus = (id, status) => {
        if (!window.confirm(t('alert'))) {
            return;
        }

        window.alert(t('loading_alert'));
        const token = Cookie.get("token");
        axios.post(changeTheCallStatusEncpoint, { id: id, status: status }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                window. alert(t('success_alert'));
                fetchData();
            })
            .catch((error) => {
                window.alert(t('bad_alert'));
            });
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilter({...filter, [name]: value});
    };

    const resetFilters = () => {
        fetchData();
        const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        setFilter({
            id: '',
            phone_number: '',
            otp: '',
            trans_id: '',
            startDate: startOfMonth,
            endDate: today,
            call_result: '',
        });
    };

    const filteredData = data.filter((row) => {
        const isDateInRange = filter.startDate && filter.endDate
            ? dayjs(row.created_at).isBetween(filter.startDate, filter.endDate, 'day', '[]')
            : true;

        const isStatusMatch = filter.call_result
            ? (row.call_result || '').includes(filter.call_result)
            : true;

        const isPhoneNumberMatch = filter.phone_number
            ? (row.phone_number || '').includes(filter.phone_number)
            : true;

        const isIdMatch = filter.id
            ? (row.id?.toString() || '').includes(filter.id)
            : true;

        const isOtpMatch = filter.otp
            ? (row.otp || '').includes(filter.otp)
            : true;

        const isTransIdMatch = filter.trans_id
            ? (row.trans_id || '').includes(filter.trans_id)
            : true;

        return isDateInRange && isStatusMatch && isPhoneNumberMatch && isIdMatch && isTransIdMatch && isOtpMatch;
    });


    const billedAmount = filteredData.reduce((acc, row) => acc + row.billing, 0);
    const recordCount = filteredData.length;
    return (
        <Box m="10px">
            <Header subtitle={t('filters')}/>
            <Box display="flex" flexDirection="row"
                 sx={{mb: isMaxWidth1200 ? "15px" : "20px", gap: isMaxWidth1200 ? "5px" : "15px"}}>
                <TextField
                    label={t('id')}
                    name="id"
                    value={filter.id}
                    onChange={handleFilterChange}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.id[100],
                            width: isMaxWidth1200 ? "70px" : "140px",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: colors.end_date[100],
                        },
                    }}
                />

                <TextField
                    label={t('phone_number')}
                    name="phone_number"
                    value={filter.phone_number}
                    onChange={handleFilterChange}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.phone_number[100],
                            width: isMaxWidth1200 ? "80px" : "140px",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: colors.end_date[100],
                        },
                    }}
                />
                <TextField
                    label={t('otp')}
                    name="otp"
                    value={filter.otp}
                    onChange={handleFilterChange}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.menu[100],
                            width: isMaxWidth1200 ? "70px" : "140px",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: colors.end_date[100],
                        },
                    }}
                />
                <TextField
                    label={t('trans_id')}
                    name="trans_id"
                    value={filter.trans_id}
                    onChange={handleFilterChange}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.menu[100],
                            width: isMaxWidth1200 ? "70px" : "140px",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: colors.end_date[100],
                        },
                    }}
                />
                <Select
                    name="call_result"
                    value={filter.call_result}
                    onChange={handleFilterChange}
                    displayEmpty
                    inputProps={{'aria-label': t('status')}}
                    sx={{
                        height: "50px",
                        lineHeight: "20px",
                        backgroundColor: colors.primary[400],
                        color: colors.customer[100],
                        width: isMaxWidth1200 ? "150px" : "190px",
                        "& .MuiSelect-icon": {
                            color: colors.customer[100],
                        },
                    }}
                >
                    <MenuItem value="">
                        <em>{t('all_call_results')}</em>
                    </MenuItem>
                    {callResultOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {t(option)}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label={t('start_date')}
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            color: colors.start_date[100],
                        },
                    }}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.start_date[100],
                            width: isMaxWidth1200 ? "128px" : "140px",
                        },
                    }}
                />
                <TextField
                    label={t('end_date')}
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            color: colors.end_date[100],
                        },
                    }}
                    InputProps={{
                        sx: {
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.end_date[100],
                            width: isMaxWidth1200 ? "128px" : "140px",
                        },
                    }}
                />
                <Button
                    variant="contained"
                    onClick={fetchFilteredData}
                    sx={{
                        height: "49px",
                        lineHeight: "20px",
                        backgroundColor: colors.balanceBG,
                        color: colors.balance,
                        fontWeight: "bold",
                        "&:hover": {
                            backgroundColor: colors.balanceBG,
                            color: colors.balance,
                        }
                    }}
                >
                    {t('search')}
                </Button>
                <Button
                    variant="contained"
                    onClick={resetFilters}
                    sx={{
                        height: "49px",
                        lineHeight: "20px",
                        backgroundColor: colors.balanceBG,
                        color: colors.balance,
                        fontWeight: "bold",
                        "&:hover": {
                            backgroundColor: colors.balanceBG,
                            color: colors.balance,
                        }
                    }}
                >
                    {t('reset')}
                </Button>

            </Box>
            <Box
                m="20px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        backgroundColor: colors.balanceBG,
                        borderRadius: "4px",
                    },
                    "& ::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                    },
                    "& ::-webkit-scrollbar": {
                        width: "5px",
                        height: "5px"
                    },
                }}
            >
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            billed_amount: billedAmount,
                            record_count: recordCount,
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default Requests;
