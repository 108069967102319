import {Box, useTheme, TextField, Select, MenuItem, Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import CustomToolbar from "../../components/CustomToolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddGateway from "../../components/add/AddGateway";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UpdateGateway from "../../components/update/UpdateGateway";
import ViewGateway from "../../components/view/ViewGateway";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
dayjs.extend(isBetween);

const Gateways = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const isMaxWidth1280 = useMediaQuery("(max-width:1280px)");
    const isMaxWidth1200 = useMediaQuery("(max-width:1200px)");
    const isMinWidth1140 = useMediaQuery("(min-width:1023px)");
    const fontSize = isMaxWidth1280 && isMinWidth1140 ? '14px' : '16px';
    const [dialogOpen, setDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const apiEndpoint = process.env.REACT_APP_GET_GATEWAYS_API_ENDPOINT;
    const [filter, setFilter] = useState({
        id: '',
        name: '',
        realm: '',
        caller_id_number: '',
        user_name: '',
        date: '',
        startDate: '',
        endDate: '',
    });
    useEffect(() => {
        setFilter(prevFilter => ({
            ...prevFilter,
        }));
    }, []);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const token = Cookie.get("token");
    let authorities = null;

    useEffect(() => {
        if (token && typeof token === 'string') {
            const decodedToken = jwtDecode(token);
            authorities = decodedToken.authorities;
            if(authorities !== null && authorities.includes('ROLE_USER')){
                setShouldNavigate(true);
            }
        }
    }, [token]);
    useEffect(() => {
        if (shouldNavigate) {
            navigate("/", { replace: true });
        }
    }, [shouldNavigate, navigate]);

    const fetchData = async () => {
        const token = Cookie.get("token");
        try {
            const response = await axios.get(apiEndpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setData(response.data);
            console.log(response.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(() => {
            fetchData();
        }, 300000);
        return () => clearInterval(intervalId);
    }, []);



    const nameOptions = [...new Set(data.map(item => item.name))];
    const userOptions = [...new Set(data.map(item => item.user_name))];

    const columns = [
        {
            field: "id", headerName: t('id'), flex: 0.4, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "name", headerName: t('name'), flex: 0.6, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "realm", headerName: t('realm'), flex: 0.4, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "caller_id_number", headerName: t('caller_id_number'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "user_name", headerName: t('users'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "created_at", headerName: t('date'), flex: 0.5, renderCell: (params) => (
                <Box style={{fontSize}}>
                    {params.value}
                </Box>
            ),
            renderHeader: (params) => (
                <Box style={{fontSize}}>
                    {params.colDef.headerName}
                </Box>
            ),
        },
        {
            field: "actions",
            headerName: t('actions'),
            flex: 1,
            renderHeader: (params) => (
                <Box style={{fontSize: '16px'}}>
                    {params.colDef.headerName}
                </Box>
            ),
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: colors.add,
                            color: 'white',
                            borderRadius: '20px',
                            width: '60px',
                            minWidth: '60px',
                            marginRight: '5px'
                        }}
                        onClick={() => openUpdateDialog(params.row)}
                    >
                        <ModeEditIcon/>
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: 'white',
                            borderRadius: '20px',
                            width: '60px',
                            minWidth: '60px',
                            marginRight: '5px'
                        }}
                        onClick={() => openViewDialog(params.row)}
                    >
                        <SearchIcon/>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            borderRadius: '20px',
                            width: '60px',
                            minWidth: '60px',
                        }}
                        onClick={() => console.log(params.row.id)}
                    >

                        <DeleteForeverIcon/>
                    </Button>
                </Box>
            ),
        }
    ];

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilter({...filter, [name]: value});
    };

    const resetFilters = () => {
        const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        setFilter({
            id: '',
            name: '',
            realm: '',
            caller_id_number: '',
            user_name: '',
            startDate: startOfMonth,
            endDate: today,
        });
    };

    const filteredData = data.filter((row) => {
        const isDateInRange = filter.startDate && filter.endDate ? dayjs(row.created_at).isBetween(filter.startDate, filter.endDate, 'day', '[]') : true;
        const isNameMatch = filter.name ? row.name.includes(filter.name) : true;
        const isRealmMatch = filter.realm ? row.realm.includes(filter.realm) : true;
        const isCallerIdMatch = filter.caller_id_number ? row.caller_id_number.includes(filter.caller_id_number) : true;
        const isUserMatch = filter.user_name ? row.user_name.includes(filter.user_name) : true;
        const isIdMatch = filter.id ? row.id.includes(filter.id) : true;

        return isDateInRange && isNameMatch && isRealmMatch && isCallerIdMatch && isUserMatch && isIdMatch;
    });

    const openDialog = () => {
        setDialogOpen(true);
        fetchData();
    };

    const closeDialog = () => {
        setDialogOpen(false);
        fetchData();
    };
    const openUpdateDialog = (rowData) => {
        setSelectedRowData(rowData);
        setUpdateDialogOpen(true);
        fetchData();
    };

    const closeUpdateDialog = () => {
        setUpdateDialogOpen(false);
        setSelectedRowData(null);
        fetchData();
    };
    const openViewDialog = (rowData) => {
        setSelectedRowData(rowData);
        setViewDialogOpen(true);
        fetchData();
    };

    const closeViewDialog = () => {
        setViewDialogOpen(false);
        setSelectedRowData(null);
        fetchData();
    };

    return (
        <Box m="10px">
            <Header subtitle={t('filters')}/>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box display="flex" flexDirection="row"
                     sx={{mb: isMaxWidth1200 ? "15px" : "20px", gap: isMaxWidth1200 ? "5px" : "15px"}}>
                    <TextField
                        label={t('id')}
                        name="id"
                        value={filter.id}
                        onChange={handleFilterChange}
                        InputProps={{
                            sx: {
                                height: "50px",
                                lineHeight: "20px",
                                backgroundColor: colors.primary[400],
                                color: colors.menu[100],
                                width: isMaxWidth1200 ? "70px" : "140px",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: colors.end_date[100],
                            },
                        }}
                    />
                    <Select
                        name="name"
                        value={filter.name}
                        onChange={handleFilterChange}
                        displayEmpty
                        inputProps={{'aria-label': t('name')}}
                        sx={{
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.customer[100],
                            width: isMaxWidth1200 ? "100px" : "140px",
                            "& .MuiSelect-icon": {
                                color: colors.customer[100],
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t('all_name')}</em>
                        </MenuItem>
                        {nameOptions.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label={t('caller_id_number')}
                        name="caller_id_number"
                        value={filter.caller_id_number}
                        onChange={handleFilterChange}
                        InputProps={{
                            sx: {
                                height: "50px",
                                lineHeight: "20px",
                                backgroundColor: colors.primary[400],
                                color: colors.menu[100],
                                width: isMaxWidth1200 ? "70px" : "140px",
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: colors.end_date[100],
                            },
                        }}
                    />
                    <Select
                        name="user_name"
                        value={filter.user_name}
                        onChange={handleFilterChange}
                        displayEmpty
                        inputProps={{'aria-label': t('users')}}
                        sx={{
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.primary[400],
                            color: colors.customer[100],
                            width: isMaxWidth1200 ? "100px" : "140px",
                            "& .MuiSelect-icon": {
                                color: colors.customer[100],
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t('all_users')}</em>
                        </MenuItem>
                        {userOptions.map((user) => (
                            <MenuItem key={user} value={user}>
                                {user}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label={t('start_date')}
                        type="date"
                        name="startDate"
                        value={filter.startDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: colors.start_date[100],
                            },
                        }}
                        InputProps={{
                            sx: {
                                height: "50px",
                                lineHeight: "20px",
                                backgroundColor: colors.primary[400],
                                color: colors.menu[100],
                                width: isMaxWidth1200 ? "128px" : "140px",
                            },
                        }}

                    />
                    <TextField
                        label={t('end_date')}
                        type="date"
                        name="endDate"
                        value={filter.endDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: colors.start_date[100],
                            },
                        }}
                        InputProps={{
                            sx: {
                                height: "50px",
                                lineHeight: "20px",
                                backgroundColor: colors.primary[400],
                                color: colors.menu[100],
                                width: isMaxWidth1200 ? "128px" : "140px",
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={resetFilters}
                        sx={{
                            height: "49px",
                            lineHeight: "20px",
                            backgroundColor: colors.balanceBG,
                            color: colors.balance,
                            fontWeight: "bold",
                            "&:hover": {
                                backgroundColor: colors.balanceBG,
                                color: colors.balance,
                            }
                        }}
                    >
                        {t('reset')}
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={openDialog}
                        sx={{
                            height: "50px",
                            lineHeight: "20px",
                            backgroundColor: colors.add,
                            color: "#FFFFFF",
                            mr: "5px",
                            "&:hover": {
                                backgroundColor: colors.add,
                                color: "#FFFFFF",
                            }
                        }}
                    >
                        {t('add')}
                    </Button>
                </Box>
            </Box>
            <Box
                m="20px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& ::-webkit-scrollbar-thumb": {
                        backgroundColor: colors.balanceBG,
                        borderRadius: "4px",
                    },
                    "& ::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                    },
                    "& ::-webkit-scrollbar": {
                        width: "5px",
                        height: "5px"
                    },
                }}
            >
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    components={{Toolbar: CustomToolbar}}
                />

                <AddGateway open={dialogOpen} handleClose={closeDialog}/>
                <UpdateGateway open={updateDialogOpen} handleClose={closeUpdateDialog} data={selectedRowData}/>
                <ViewGateway open={viewDialogOpen} handleClose={closeViewDialog} data={selectedRowData}/>
            </Box>
        </Box>
    );
};

export default Gateways;
