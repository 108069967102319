import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Switch,
    FormControlLabel, DialogActions,
} from '@mui/material';
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {tokens} from "../../theme";
import axios from "axios";
import Cookie from "js-cookie";
import {jwtDecode} from "jwt-decode";
const UpdateGateway = ({open, handleClose,data}) => {
    const theme1 = useTheme();
    const colors = tokens(theme1.palette.mode);
    const {t} = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const token = Cookie.get("token");
    const decodedToken = jwtDecode(token);
    const apiEndpoint = process.env.REACT_APP_UPDATE_GATEWAY_API_ENDPOINT;
    const handleFormSubmit = async (values) => {
        try {
            await axios.post(apiEndpoint, values, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((response )=>{
                handleClose();
            });

        } catch (error) {
        }
    };

    const checkoutSchema = yup.object().shape({
        name: yup.string().required(t('required')),
        realm: yup.string(),
        register: yup.boolean(),
        username: yup.string(),
        password: yup.string(),
        caller_id_number: yup.string().required(t('required')),
        transport: yup.string(),
        channel_count: yup.number().required(t('required')),
        user_id:yup.string(),
    });

    const initialValues = {
        name: data?.name || '',
        realm: data?.realm || '',
        register: data?.register || false,
        username: data?.username || '',
        password: data?.password || '',
        caller_id_number: data?.caller_id_number || '',
        transport: data?.transport || '',
        channel_count: data?.channel_count || '',
        user_id:decodedToken.user_id

    };

    return (
        <Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        maxWidth: '1200px',
                        width: '1100px',
                        height: '550px',
                    },
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: colors.addDialog[100], color:colors.addDialog[400]}}>
                    <DialogTitle id="responsive-dialog-title"
                                 sx={{fontWeight: "600", fontSize: "18px"}}>{t('update_gateway')}</DialogTitle>
                    <CloseIcon sx={{margin: '15px 15px 0 0', cursor: 'pointer', fontWeight: "600", fontSize: "26px",color:colors.addDialog[400]}}
                               onClick={handleClose}/>
                </Box>
                <DialogContent>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        paddingX: '7px',
                                        '& > div': {gridColumn: isNonMobile ? undefined : 'span 2'},
                                    }}
                                >
                                    <TextField
                                        label={t('name')}
                                        name="name"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('realm')}
                                        name="realm"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.realm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.realm && !!errors.realm}
                                        helperText={touched.realm && errors.realm}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="register"
                                                checked={values.register}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={t('register')}
                                        sx={{gridColumn: 'span 2'}}
                                    />
                                    <TextField
                                        label={t('username')}
                                        name="username"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('password')}
                                        name="password"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('caller_id_number')}
                                        name="caller_id_number"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.caller_id_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.caller_id_number && !!errors.caller_id_number}
                                        helperText={touched.caller_id_number && errors.caller_id_number}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />

                                    <TextField
                                        label={t('transport')}
                                        name="transport"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.transport}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.transport && !!errors.transport}
                                        helperText={touched.transport && errors.transport}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('channel_count')}
                                        name="channel_count"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.channel_count}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.channel_count && !!errors.channel_count}
                                        helperText={touched.channel_count && errors.channel_count}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" sx={{ position: 'absolute', bottom: '10px', right: '20px' }} >
                                    <DialogActions>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            sx={{
                                                minWidth: '150px',
                                                backgroundColor: colors.addDialog[200],
                                                color: colors.addDialog[400],
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                "&:hover": {
                                                    backgroundColor: colors.addDialog[200],
                                                    color: colors.addDialog[400],
                                                },
                                                mr: "5px"
                                            }}
                                            onClick={handleClose}
                                        >
                                            {t('close')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            sx={{
                                                minWidth: '150px',
                                                backgroundColor: colors.addDialog[100],
                                                color: colors.addDialog[400],
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                "&:hover": {
                                                    backgroundColor: colors.addDialog[100],
                                                    color: colors.addDialog[400],
                                                }
                                            }}
                                        >
                                            {t('create')}
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default UpdateGateway;
