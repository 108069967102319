import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../scenes/auth/AuthContext";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookie from 'js-cookie';

const PrivateRoute = ({ element }) => {
    const { token, logout } = useAuth();
    const [loading, setLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const apiEndpoint = process.env.REACT_APP_VERIFY_API_ENDPOINT;
    useEffect(() => {
        const validateToken = async () => {
            const cookieToken = Cookie.get("token");
            if (cookieToken) {
                try {
                    const decodedToken = jwtDecode(cookieToken);

                    const response = await axios.post(
                        apiEndpoint,
                        {
                            username: decodedToken.sub,
                            token: cookieToken
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${cookieToken}`,
                            }
                        }
                    );

                    if (response.status === 200) {
                        setIsValid(true);
                    } else {
                        setIsValid(false);
                        logout();
                    }
                } catch (error) {
                    setIsValid(false);
                    logout();
                }
            } else {
                setIsValid(false);
                logout();
            }

            setLoading(false);
        };

        validateToken();
    }, [token, logout]);
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isValid) {
        return <Navigate to="/login" replace />;
    }

    return element;
};

export default PrivateRoute;
