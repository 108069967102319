import {ResponsiveBar} from "@nivo/bar";
import {useTheme} from "@mui/material";
import {tokens} from "../theme";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

const BarChart = ({isDashboard = false}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_GET_CALL_RESULTS_MONTHLY_API_ENDPOINT;
    const token = Cookie.get("token");
    const decodedToken = jwtDecode(token);
    useEffect(() => {
        const fetchData = async () => {
            const token = Cookie.get("token");
            try {
                const response = await axios.post(apiEndpoint,
                    {
                        id: decodedToken.user_id
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                setData(response.data);
            } catch (error) {
            }
        };
        fetchData();
    }, []);

    const months = [
        "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"
    ];

    const getMonthlyStats = (month) => {
        return data.reduce((acc, item) => {
            const titleParts = item.title.split('_');
            if (titleParts[0] === month) {
                const statType = titleParts[1];
                acc[statType] = item.count;
            }
            return acc;
        }, { requests: 0, processed: 0, failed: 0 });
    };
    const barData = months.map(month => {
        const stats = getMonthlyStats(month);
        return {
            month: t(month),
            Request: stats.requests,
            RequestColor: "#4049FF",
            Processed: stats.processed,
            ProcessedColor: "hsl(296, 70%, 50%)",
            Failed: stats.failed,
            FailedColor: "hsl(97, 70%, 50%)",
        };
    });
    const translatedKeys = {
        Request: t('requests'),
        Processed: t('processed'),
        Failed: t('failed'),
    };

    const translatedData = barData.map(entry => ({
        ...entry,
        month: t(entry.month),
        [translatedKeys.Request]: entry.Request,
        [translatedKeys.Processed]: entry.Processed,
        [translatedKeys.Failed]: entry.Failed,
        [`${translatedKeys.Request}Color`]: entry.RequestColor,
        [`${translatedKeys.Processed}Color`]: entry.ProcessedColor,
        [`${translatedKeys.Failed}Color`]: entry.FailedColor,
    }));

    const myColors = [
        "#ffc107",//requests
        "#007bff",//processed
        "#dc3545",//failed
    ];


    return (
        <ResponsiveBar
            data={translatedData}
            keys={[
                translatedKeys.Request,
                translatedKeys.Processed,
                translatedKeys.Failed
            ]}
            indexBy="month"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0}
            innerPadding={4}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={myColors}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: { id: 'fries' },
                    id: 'dots'
                },
                {
                    match: { id: 'sandwich' },
                    id: 'lines'
                }
            ]}
            borderRadius={4}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [['darker', '1']]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t('results_by_months'),
                legendPosition: 'middle',
                legendOffset: 33
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: t('requests'),
                legendPosition: 'middle',
                legendOffset: -40
            }}
            enableGridX={true}
            totalsOffset={0}
            labelSkipWidth={16}
            labelTextColor="white"
            labelPosition="end"
            labelOffset={14}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 2,
                    symbolSize: 20,
                    itemDirection: 'left-to-right'
                }
            ]}
            role="application"
            ariaLabel={t('BarChartAriaLabel')}
            barAriaLabel={e =>
                t('BarChartAriaLabelFormat', { id: e.id, value: e.formattedValue, index: e.indexValue })
            }
            theme={{
                axis: {
                    ticks: {
                        text: {
                            fill: colors.grey[100]
                        }
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100]
                        }
                    }
                },
                legends: {
                    text: {
                        fill: colors.grey[100]
                    }
                },
                labels: {
                    text: {
                        fill: colors.grey[100]
                    }
                },
                tooltip: {
                    container: {
                        background: colors.tooltip[200],
                        color: colors.tooltip[100]
                    }
                }
            }}
        />
    );
};

export default BarChart;
