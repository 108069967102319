import {Box, IconButton, useTheme, Typography} from "@mui/material";
import {useContext, useState} from "react";
import {ColorModeContex, tokens} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountMenu from "../../components/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import Cookie from "js-cookie";
import {jwtDecode} from "jwt-decode";

const Topbar = ({balance,page_name}) => {
    const isMaxWidth1280 = useMediaQuery("(max-width:1280px)");
    const isMinWidth1140 = useMediaQuery("(min-width:1023px)");
    const typographyVariant = isMaxWidth1280 && isMinWidth1140 ? 'h6' : 'h4';
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContex);
    const [anchorEl, setAnchorEl] = useState(null);
    const token = Cookie.get("token");
    let name = '';

    if (token && typeof token === 'string') {
        const decodedToken = jwtDecode(token);
        name = decodedToken.sub;
    }
    const handlePersonIconClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const uppercaseSub =name.toUpperCase();

    return (
        <Box display="flex" alignItems="center"  p={2} sx={{ backgroundColor: colors.card[100]}}>
            <Box flex={1} display="flex" justifyContent="left">
                <Box flex={1} display="flex" justifyContent="left">
                    <Typography
                        variant={typographyVariant}
                        color={colors.grey[100]}
                        fontWeight="bold"
                    >
                        {uppercaseSub}
                    </Typography>
                    <Box sx={{display:"flex",alignItems:'center',justifyContent:"center"}}>
                    <Box
                        sx={{
                            width: '5px',
                            height: '5px',
                            borderRadius: '50%',
                            backgroundColor: colors.dote,
                            margin: '0 5px',
                        }}
                    />
                    </Box>
                    <Typography variant={typographyVariant}  fontWeight="bold" padding ="0 0 0 2px" color={colors.balanceBG} sx={{ opacity: 0.7,}}>
                          {page_name}
                    </Typography>
                </Box>
            </Box>



            <Box flex={1} display="flex" >

            </Box>

            {/* ICONS */}
            <Box display="flex" alignItems="center" gap={2}>
                {/* BALANCE */}
                {/*<Box sx={{backgroundColor:colors.balanceBG, borderRadius:"5px", height:"35px",width: `${calculateWidth(balance)}px`, }}>*/}
                {/*    <Typography*/}
                {/*                color={colors.card[100]}*/}
                {/*                fontWeight="bold"*/}
                {/*                sx={{fontSize:"18px", marginLeft: isNestHubMaxWidth ? "5px" :"",*/}
                {/*                    display:"flex",justifyContent:"center",marginTop:"4px",whiteSpace: "nowrap",color:colors.balance}}*/}
                {/*    >*/}
                {/*        {t('balance')}: {formattedBalance}*/}
                {/*    </Typography>*/}

                {/*</Box>*/}

                <IconButton onClick={colorMode.toogleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <LightModeOutlinedIcon />
                    ) : (
                        <DarkModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton onClick={handlePersonIconClick}>
                    <PersonOutlinedIcon />
                </IconButton>
                <AccountMenu anchorEl={anchorEl} handleClose={handleMenuClose} />
            </Box>
        </Box>
    );
};
export default Topbar;
