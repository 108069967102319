import {Box, Button, Select, MenuItem, FormControl, TextField, InputLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import {useTheme} from "@mui/material/styles";
import {tokens} from "../../theme";
import useStyles1 from "../../assets/style/style";
import useStyles2 from "../../assets/style/style2";
import {AlertSuccess, AlertError} from "../../components/Alert";

const SendOTP = () => {
    const {t, i18n} = useTranslation();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const token = Cookie.get("token");
    const decodedToken = jwtDecode(token);
    const theme1 = useTheme();
    const colors = tokens(theme1.palette.mode);
    const apiEndpoint = process.env.REACT_APP_SEND_OTP_API_ENDPOINT;
    const apiEndpoint4 = process.env.REACT_APP_GET_GATEWAYS_API_ENDPOINT;
    const user_id = decodedToken.user_id;
    const isMaxWidth1280 = useMediaQuery("(max-width:1280px)");
    const styles1 = useStyles1();
    const styles2 = useStyles2();
    const style = isMaxWidth1280 ? styles2 : styles1;
    const [alert, setAlert] = useState(null);
    const [saveButtonState, setSaveButtonState] = useState({
        text: t('send'),
        color: "#FFFFFF",
        backgroundColor: colors.add,
        "&:hover": {backgroundColor: colors.add, color: "#FFFFFF"}
    });
    let authorities = null;

    if (token && typeof token === 'string') {
        const decodedToken = jwtDecode(token);
        authorities = decodedToken.authorities;
    }

    const handleFormSubmit = async (values) => {
        const singlePayload = {
            otp: values.otp,
            phone_number: values.phone_number,
            user_id: user_id,
            gateway_id: values.gateways,
            device_name: values.device_type, // Using the selected device type
            trans_id: uuidv4().replace(/-/g, '').substring(0, 18),
        };
        try {
            const response = await axios.post(apiEndpoint, singlePayload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setAlert(<AlertSuccess title={t('success')} message={
                i18n.exists(response.data)
                    ? t(response.data)
                    : t('success_message_menu') + " " + response.data
            }
            />);
        } catch (error) {
            const errorMessage = error?.response?.data || error.message || t('error_message_menu');
            setAlert(<AlertError title={t('error')} message={errorMessage} />);
        }
    };

    const [gatewayOptions, setGatewayOptions] = useState([]);

    useEffect(() => {
        const fetchGatewayOptions = async () => {
            try {
                const response = await axios.get(
                    apiEndpoint4,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                const gateway = response.data.map(gateway => ({
                    value: gateway.id,
                    label: gateway.name,
                }));
                setGatewayOptions(gateway);
            } catch (error) {
            }
        };
        fetchGatewayOptions();
    }, [token, decodedToken.user_id, gatewayOptions, apiEndpoint4]);


    const phoneRegExp0 = /^(?:994)(50|51|55|60|70|77|99|10)(\d{7})$/;
    const phoneRegExp = /^(050|051|055|060|070|077|099|010)(\d{7})$/;

    const checkoutSchema = yup.object().shape({
        otp: yup
            .string()
            .matches(/^\d{4}$/, t('otp_must_be_4_digits')),
        gateways: yup.string(),
        phone_number: yup.string()
            .required(t('required'))
            .test('valid-phones', 'Invalid phone numbers', function (value) {
                const phoneNumbers = value.split("\n").map(num => num.trim());
                for (const number of phoneNumbers) {
                    if (!phoneRegExp.test(number) && !phoneRegExp0.test(number)) {
                        return false;
                    }
                }
                return true;
            }),
        device_type: yup.string().required(t('required')),
    });

    const initialValues = {
        otp: "",
        phone_number: "",
        gateways: "",
        device_type: "", // Empty by default as requested
    };

    return (
        <Box m="20px">
            <Header title={t('send-otp')} subtitle=""/>
            <br/>
            <Box sx={{maxWidth: '800px'}}>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                    <Box sx={{flex: 6,display:"flex",justifyContent:"space-between"}}>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                  setErrors
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box
                                        display="grid"
                                        gap="20px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                            width: "550px",
                                        }}
                                    >
                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 4" }}
                                                     error={!!touched.gateways && !!errors.gateways}>
                                            <InputLabel id="gateways">{t('gateways')}</InputLabel>
                                            <Select
                                                labelId="gateways"
                                                id="gateways"
                                                name="gateways"
                                                value={values.gateways}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {gatewayOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.gateways && errors.gateways && (
                                                <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.gateways}</div>
                                            )}
                                        </FormControl>

                                        <TextField
                                            label={t('otp')}
                                            name="otp"
                                            value={values.otp}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d{0,4}$/.test(value)) {
                                                    setFieldValue("otp", value);
                                                    checkoutSchema
                                                        .validateAt("otp", { otp: value })
                                                        .then(() => setErrors((prev) => ({ ...prev, otp: undefined })))
                                                        .catch((err) => setErrors((prev) => ({ ...prev, otp: err.message })));
                                                }
                                            }}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                                max: 9999,
                                                inputMode: "numeric",
                                                pattern: "[0-9]*"
                                            }}
                                            sx={{
                                                gridColumn: "span 4",
                                                height: "50px",
                                                backgroundColor: colors.primary[400],
                                                color: colors.menu[100],
                                            }}
                                            InputLabelProps={{
                                                style: { color: colors.end_date[100] },
                                            }}
                                        />

                                        <TextField
                                            label={t('phone_number')}
                                            name="phone_number"
                                            value={values.phone_number}
                                            onChange={handleChange}
                                            sx={{
                                                gridColumn: "span 4",
                                                height: "50px",
                                                backgroundColor: colors.primary[400],
                                                color: colors.menu[100],
                                            }}
                                            InputLabelProps={{
                                                style: { color: colors.end_date[100] },
                                            }}
                                        />

                                        {/* Device Type Radio Buttons */}
                                        <FormControl
                                            component="fieldset"
                                            sx={{ gridColumn: "span 4" }}
                                            error={!!touched.device_type && !!errors.device_type}
                                        >
                                            <InputLabel
                                                sx={{
                                                    position: 'static',
                                                    transform: 'none',
                                                    marginBottom: '8px',
                                                    color: colors.end_date[100]
                                                }}
                                            >
                                                {t('device_type')}
                                            </InputLabel>
                                            <RadioGroup
                                                row
                                                name="device_type"
                                                value={values.device_type}
                                                onChange={handleChange}
                                            >
                                                <FormControlLabel
                                                    value="Android"
                                                    control={<Radio />}
                                                    label="Android"
                                                    sx={{
                                                        color: colors.menu[100],
                                                        '& .MuiRadio-root': {
                                                            color: colors.menu[100],
                                                        }
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value="IOS"
                                                    control={<Radio />}
                                                    label="IOS"
                                                    sx={{
                                                        color: colors.menu[100],
                                                        '& .MuiRadio-root': {
                                                            color: colors.menu[100],
                                                        }
                                                    }}
                                                />
                                            </RadioGroup>
                                            {touched.device_type && errors.device_type && (
                                                <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.device_type}</div>
                                            )}
                                        </FormControl>
                                    </Box>

                                    <br/>
                                    <Box display="flex" justifyContent="right" mt="20px">
                                        <Button
                                            sx={{
                                                ...style.mainMenu.saveButton,
                                                color: saveButtonState.color,
                                                backgroundColor: saveButtonState.backgroundColor,
                                                "&:hover": saveButtonState["&:hover"]
                                            }}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {saveButtonState.text}
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                    <Box sx={{flex: 1, ml: "20px", }}>
                        {alert}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SendOTP;
