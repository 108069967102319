import * as React from 'react';
import { Box, Menu, MenuItem, ListItemIcon, Divider } from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cookie from "js-cookie";

export default function AccountMenu({ anchorEl, handleClose }) {
    const open = Boolean(anchorEl);
    const { t, i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = React.useState(i18n.language);
    const navigate = useNavigate();

    const handleMenuItemClick = (value) => {
        setSelectedValue(value);
        i18n.changeLanguage(value);
        handleClose();
    };

    const handleLogoutButtonClick = () => {
        Cookie.remove('token');
        navigate("/login");
    };

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box display="flex" flexDirection="row">
                    <MenuItem
                        selected={selectedValue === 'az'}
                        onClick={() => handleMenuItemClick('az')}
                        sx={{
                            bgcolor: selectedValue === 'az' ? 'primary.light' : 'background.paper',
                            color: selectedValue === 'az' ? 'white' : 'text.primary',
                            '&:hover': {
                                bgcolor: 'primary.main',
                                color: 'white',
                            }
                        }}
                    >
                        {t('az')}
                    </MenuItem>
                    <MenuItem
                        selected={selectedValue === 'ru'}
                        onClick={() => handleMenuItemClick('ru')}
                        sx={{
                            bgcolor: selectedValue === 'ru' ? 'primary.light' : 'background.paper',
                            color: selectedValue === 'ru' ? 'white' : 'text.primary',
                            '&:hover': {
                                bgcolor: 'primary.main',
                                color: 'white',
                            }
                        }}
                    >
                        {t('ru')}
                    </MenuItem>
                    <MenuItem
                        selected={selectedValue === 'en'}
                        onClick={() => handleMenuItemClick('en')}
                        sx={{
                            bgcolor: selectedValue === 'en' ? 'primary.light' : 'background.paper',
                            color: selectedValue === 'en' ? 'white' : 'text.primary',
                            '&:hover': {
                                bgcolor: 'primary.main',
                                color: 'white',
                            }
                        }}
                    >
                        {t('en')}
                    </MenuItem>
                </Box>
                <Divider />
                <MenuItem onClick={handleLogoutButtonClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('logout')}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
