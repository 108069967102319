import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
const StatBox = ({ title, subtitle, icon, progress, increase }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const stateBoxs = useMediaQuery("(max-width:1200px)");
    return (
        <Box width="80%" sx={{m:stateBoxs ? "0 20px":"0 30px"}}>
            <Box display="flex"
                justifyContent="space-between">
                <Box>
                    <Typography sx={{ fontSize:stateBoxs? "16px":"20px",color: colors.cardSubtitle[100]}}>
                        {subtitle}
                    </Typography>
                    <Typography sx={{marginTop:"5px", fontSize:stateBoxs? "22px":"30px", fontWeight:stateBoxs? "700":"800", color: colors.cardTitle[100] }}>
                        {title}
                    </Typography>
                </Box>
                <Box>
                    {icon}
                </Box>
            </Box>
        </Box>
    );
}
export default StatBox;