import {useState} from "react";
import {ProSidebar, Menu, MenuItem} from "react-pro-sidebar";
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {tokens} from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssignmentIcon from '@mui/icons-material/Assignment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GroupIcon from '@mui/icons-material/Group';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {jwtDecode} from "jwt-decode";
import Cookie from "js-cookie";

const Item = ({title, to, icon, selected, setSelected}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to}/>
        </MenuItem>
    );
};

const Sidebar = () => {
    const isNestHubMaxHeight = useMediaQuery("(max-height:800px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const {t} = useTranslation();
    const token = Cookie.get("token");
    let authorities = null;

    if (token && typeof token === 'string') {
        const decodedToken = jwtDecode(token);
        authorities = decodedToken.authorities;
    }


    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.card[100]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 10px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
                "& ::-webkit-scrollbar-thumb": {
                    backgroundColor: `${colors.balanceBG}`,
                    borderRadius: "4px",

                },
                "& ::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                },
                "& ::-webkit-scrollbar": {
                    width: "5px",
                    height: "5px"
                },

                height: isNestHubMaxHeight ? "100vh" : "100vh",
                color: isNestHubMaxHeight ? "#adadad" : "inherit",
                textAlign: isNestHubMaxHeight ? "left" : "inherit",
                position: isNestHubMaxHeight ? "relative" : "inherit",
                zIndex: isNestHubMaxHeight ? "1009" : "auto",
                transition: isNestHubMaxHeight ? "width, left, right, 0.3s" : "inherit",
            }}
        >

            <ProSidebar collapsed={isCollapsed}>
                <Menu
                    iconShape="square"
                >
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
                        style={{
                            margin: "10px 0 10px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="right"
                                alignItems="center"
                                ml="15px"
                            >
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={isCollapsed ? undefined : "2%"}>
                        <Item
                            title={t('dashboard')}
                            to="/"
                            icon={<HomeOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 10px"}}
                        >
                            {t('send')}
                        </Typography>
                        <Item
                            title={t('send-otp')}
                            to="/otp-send"
                            icon={<TelegramIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 10px"}}
                        >
                            {t('reports')}
                        </Typography>
                        <Item
                            title={t('requests')}
                            to="/call-requests"
                            icon={<AssignmentIcon/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {authorities !== null && authorities.includes('ROLE_ADMIN') && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{m: "15px 0 5px 10px"}}
                                >
                                    {t('users')}
                                </Typography>
                                <Item
                                    title={t('users')}
                                    to="/users"
                                    icon={<GroupIcon/>}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 0 5px 10px"}}
                        >
                            {t('creating')}
                        </Typography>
                        {authorities !== null && authorities.includes('ROLE_ADMIN') && (
                            <>
                                <Item
                                    title={t('gateways')}
                                    to="/gateways"
                                    icon={<MeetingRoomIcon/>}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}
                    </Box>
                </Menu>

            </ProSidebar>

        </Box>
    );
};

export default Sidebar;
