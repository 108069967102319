import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Switch,
    FormControlLabel, DialogActions,
} from '@mui/material';
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {tokens} from "../../theme";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

const UpdateUser = ({open, handleClose,data}) => {
    const theme1 = useTheme();
    const colors = tokens(theme1.palette.mode);
    const {t} = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const cookieToken = Cookie.get("token");
    const decodedToken = jwtDecode(cookieToken);
    const apiEndpoint = process.env.REACT_APP_UPDATE_USER_API_ENDPOINT;
    const handleFormSubmit = async (values) => {
        try {
           await axios.post(apiEndpoint, values, {
                headers: {
                    'Authorization': `Bearer ${cookieToken}`,
                    'Content-Type': 'application/json'
                }
            }).then((response )=>{
                handleClose();
            }).catch((response)=>{
            });

        } catch (error) {
        }
    };

    const phoneRegExp = /^(?:994)(50|51|55|60|70|77|99|10)(\d{7})$/;

    const checkoutSchema = yup.object().shape({
        username: yup.string(),
        phone_number: yup.string().matches(phoneRegExp),
        password: yup.string().matches(/^\S*$/, t('password_no_spaces')),
        email: yup.string().email(t('invalid_email')),
        note: yup.string(),
        isAdmin: yup.boolean()
    });

    const initialValues = {
        added_by_user_id:decodedToken.sub,
        id:data?.id || '',
        username: data?.username || '',
        email: data?.email || '',
        password:"",
        phone_number: data?.phone_number || '',
        note: data?.note || '',
        isAdmin: data?.role === 'Admin',
    };

    return (
        <Box>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                PaperProps={{
                    sx: {
                        maxWidth: '1200px',
                        width: '1100px',
                        height: '400px',
                    },
                }}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: colors.addDialog[100],color:colors.addDialog[400]}}>
                    <DialogTitle id="responsive-dialog-title"
                                 sx={{fontWeight: "600", fontSize: "18px"}}>{t('update_user')}</DialogTitle>
                    <CloseIcon sx={{margin: '15px 15px 0 0', cursor: 'pointer', fontWeight: "600", fontSize: "26px",color:colors.addDialog[400]}}
                               onClick={handleClose}/>
                </Box>
                <DialogContent>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        paddingX: '7px',
                                        '& > div': {gridColumn: isNonMobile ? undefined : 'span 2'},
                                    }}
                                >
                                    <TextField
                                        label={t('name')}
                                        name="username"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('password')}
                                        name="password"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('email')}
                                        name="email"
                                        sx={{ gridColumn: 'span 2' }}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <TextField
                                        label={t('phone_number')}
                                        name="phone_number"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.phone_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.phone_number && !!errors.phone_number}
                                        helperText={touched.phone_number && errors.phone_number}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="isAdmin"
                                                checked={values.isAdmin}
                                                onChange={handleChange}
                                            />
                                        }
                                        label={t('isAdmin')}
                                        sx={{gridColumn: 'span 2'}}
                                    />
                                    <TextField
                                        label={t('note')}
                                        name="note"
                                        sx={{gridColumn: 'span 2'}}
                                        value={values.note}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={!!touched.note && !!errors.note}
                                        helperText={touched.note && errors.note}
                                        InputProps={{
                                            sx: {
                                                backgroundColor:colors.addDialog[200],
                                                color: colors.addDialog[300],
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color:  colors.addDialog[400],
                                            },
                                        }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end"
                                     sx={{position: 'absolute', bottom: '10px', right: '20px'}}>
                                    <DialogActions>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            sx={{
                                                minWidth: '150px',
                                                backgroundColor: colors.addDialog[100],
                                                color: colors.end_date,
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                "&:hover": {
                                                    backgroundColor: colors.addDialog[100],
                                                    color: colors.addDialog[400],
                                                },
                                                mr: "5px"
                                            }}
                                            onClick={handleClose}
                                        >
                                            {t('close')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            sx={{
                                                minWidth: '150px',
                                                backgroundColor: colors.addDialog[100],
                                                color: colors.end_date,
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                "&:hover": {
                                                    backgroundColor: colors.addDialog[100],
                                                    color: colors.addDialog[400],
                                                }
                                            }}
                                        >
                                            {t('update')}
                                        </Button>
                                    </DialogActions>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default UpdateUser;
