import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isMaxWidth1280 = useMediaQuery("(max-width:1280px)");
    const isMinWidth1140 = useMediaQuery("(min-width:1023px)");
    const typographyVariant = isMaxWidth1280 && isMinWidth1140 ? 'h4' : 'h2';

    return (
        <Box
            display="flex"
            flexDirection="row"
            sx={{ mb: "20px" }}
        >
            <Typography
                variant={typographyVariant}
                color={colors.grey[100]}
                fontWeight="bold"
            >
                {title}
            </Typography>
            <Typography
                variant={typographyVariant}
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{mt:"5px"}}
            >
                {subtitle}
            </Typography>
        </Box>
    );
}

export default Header;
