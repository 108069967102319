import React from 'react';
import {GridToolbarExport} from '@mui/x-data-grid';
import {Box, Typography, useTheme} from '@mui/material';
import {tokens} from "../theme";
import {useTranslation} from "react-i18next";

const CustomToolbar = ({billed_amount, record_count}) => {
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
        const adjustedBilledAmount = billed_amount || 0;
        const adjustedRecordCount = record_count || 0;
        const {t} = useTranslation();

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box display="flex" alignItems="center">
                    {adjustedRecordCount !== 0 && (
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" fontWeight="600" color={colors.grey[100]}>
                                {t('record_count')}: {adjustedRecordCount}
                            </Typography>
                        </Box>
                    )}
                    {adjustedBilledAmount !== 0 && (
                        <Box display="flex" alignItems="center" p="0 0 0 5px">
                            <Typography variant="h6" fontWeight="600" color={colors.grey[100]}>
                                {t('billed_amount')}: {adjustedBilledAmount}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box>
                    <GridToolbarExport sx={{color: colors.primary[100]}}/>
                </Box>
            </Box>
        );
    }
;
export default CustomToolbar;
