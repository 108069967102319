// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&family=Source+Serif+4:opsz,wght@8..60,400;8..60,600;8..60,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Nunito', sans-serif !important;
}

@media  screen and (max-width: 1280px) {
  .content {
    width: 100%;
  }
}

body {
  font-family: 'Nunito', sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;EAKE,YAAY;EACZ,WAAW;EACX,4CAA4C;AAC9C;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,4CAA4C;EAC5C,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&family=Source+Serif+4:opsz,wght@8..60,400;8..60,600;8..60,700&display=swap');\n\nhtml,\nbody,\n#root,\n.app,\n.content {\n  height: 100%;\n  width: 100%;\n  font-family: 'Nunito', sans-serif !important;\n}\n\n@media  screen and (max-width: 1280px) {\n  .content {\n    width: 100%;\n  }\n}\n\nbody {\n  font-family: 'Nunito', sans-serif !important;\n  font-optical-sizing: auto;\n  font-style: normal;\n}\n\n.app {\n  display: flex;\n  position: relative;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n::-webkit-scrollbar-track {\n  background: #e0e0e0;\n}\n\n::-webkit-scrollbar-thumb {\n  background: #888;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
