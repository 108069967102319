import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../scenes/auth/AuthContext";
import "../../assets/css/LoginForm.css";

const LoginForm = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { login } = useAuth();
    const apiEndpoint = process.env.REACT_APP_LOGIN_API_ENDPOINT;
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const response = await axios.post(apiEndpoint, {
                username,
                password,
            });

            const token = response.data.token;
            login(token);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("Invalid username or password");
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    };

    return (
        <div id="login-form">
            <h1>Login</h1>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <input type="submit" value="Submit" />
            </form>
        </div>
    );
};

export default LoginForm;
