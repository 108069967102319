import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens 
export const tokens = (mode) => ({
    ...(mode === "dark"
        ? {
            grey: {
                100: "#e0e0e0",
                200: "#c2c2c2",
                300: "#a3a3a3",
                400: "#858585",
                500: "#666666",
                600: "#525252",
                700: "#3d3d3d",
                800: "#292929",
                900: "#c2c2c2"
            },
            primary: {
                100: "#d0d1d5",
                200: "#a1a4ab",
                300: "#727681",
                400: "#1F2A40",
                500: "#141b2d",
                600: "#101624",
                700: "#0c101b",
                800: "#080b12",
                900: "#040509"
            },
            greenAccent: {
                100: "#dbf5ee",
                200: "#b7ebde",
                300: "#94e2cd",
                400: "#70d8bd",
                500: "#4cceac",
                600: "#3da58a",
                700: "#2e7c67",
                800: "#1e5245",
                900: "#0f2922"
            },
            redAccent: {
                100: "#f8dcdb",
                200: "#f1b9b7",
                300: "#e99592",
                400: "#e2726e",
                500: "#db4f4a",
                600: "#af3f3b",
                700: "#832f2c",
                800: "#58201e",
                900: "#2c100f",
                1000:"#FF4C4C",
                1100:"#9f0a0a"
            },
            blueAccent: {
                100: "#e1e2fe",
                200: "#c3c6fd",
                300: "#a4a9fc",
                400: "#868dfb",
                500: "#6870fa",
                600: "#262e3a",
                700: "#323D4E",
                800: "#2a2d64",
                900: "#151632"
            },
            lineChart:{
                100:"#e0e0e0"
            },
            dashboard_row_1_1:"#0068ef",
            dashboard_row_1_2:"#52f600",
            dashboard_row_1_3:"#ff0000",
            dashboard_row_1_4:"#fffb00",
            dashboard_row_2_1:"#394252",
            tooltip:{
                100:"#e1e2fe",
                200:"#141b2d"
            },
            start_date: {
                100:"#d0d1d5",
                200:"#040509"
            },
            end_date: {
                100:"#d0d1d5",
                200:"#040509"
            },
            id:{
                100:"#d0d1d5",
                200:"#040509"
            },
            customer:{
                100:"#d0d1d5",
                200:"#040509"
            },
            phone_number:{
                100:"#d0d1d5",
                200:"#040509"
            },
            menu:{
                100:"#d0d1d5",
                200:"#040509"
            },
            status:{
                100:"#d0d1d5",
                200:"#040509"
            },
            body:{
                100:"#1B2431"
            },
            card:{
                100:"#273142",
            },
            cardSubtitle:{
                100:"#898a8c",
            },
            cardTitle:{
                100:"#FFFFFF",
            },
            barchartBG:{
                100:"#273142"
            },
            barchartStroke:{
                100:"#394252",
            },
            transactionCost:{
                100:"#9ee8a6"
            },
            cardIcons:{
                100:"#808080",//expired
                200:"#007bff",//processed
                300:"#ffc107",//requests
                400:"#dc3545",//failed
            },
            cardIconsBG: {
                100: "#FFFFFF",//expired
                200: "#FFFFFF",//processed
                300: "#FFFFFF",//requests
                400: "#FFFFFF",//failed
            },
            dote:"#7797d3",
            balanceBG:"#4880FF",
            balance:"#efefef",
            add:"#2e7c67",
            addDialog:{
                100:"#323D4E",
                200:"#333848",
                300:"#f2f0f0",
                400:"#f2f0f0"
            },

            sidebarBG:{
                100:"#141b2d",
            },
            greeting:{
                100:"#abc5ff",
                200:"#185dfc"
            }
        }
        : {
            grey: {
                100: "#141414",
                200: "#292929",
                300: "#3d3d3d",
                400: "#525252",
                500: "#666666",
                600: "#858585",
                700: "#a3a3a3",
                800: "#c2c2c2",
                900: "#e0e0e0"
            },
            primary: {
                100: "#040509",
                200: "#080b12",
                300: "#0c101b",
                400: "#f2f0f0",
                500: "#141b2d",
                600: "#434957",
                700: "#727681",
                800: "#a1a4ab",
                900: "#d0d1d5"
            },
            greenAccent: {
                100: "#0f2922",
                200: "#1e5245",
                300: "#2e7c67",
                400: "#3da58a",
                500: "#4cceac",
                600: "#70d8bd",
                700: "#94e2cd",
                800: "#b7ebde",
                900: "#dbf5ee"
            },
            redAccent: {
                100: "#f8dcdb",
                200: "#f1b9b7",
                300: "#e99592",
                400: "#e2726e",
                500: "#db4f4a",
                600: "#af3f3b",
                700: "#832f2c",
                800: "#58201e",
                900: "#2c100f",
                1000:"#FF4C4C",
                1100:"#9f0a0a"
            },
            blueAccent: {
                100: "#151632",
                200: "#2a2d64",
                300: "#3e4396",
                400: "#535ac8",
                500: "#6870fa",
                600: "#868dfb",
                700: "#9EC8F7",
                800: "#c3c6fd",
                900: "#e1e2fe"
            },
            lineChart:{
                100:"#141414"
            },
            dashboard_row_1_1:"#0068ef",
            dashboard_row_1_2:"#52f600",
            dashboard_row_1_3:"#ff0000",
            dashboard_row_1_4:"#fffb00",
            dashboard_row_2_1:"#e0e0e0",
            tooltip:{
                100:"#e1e2fe",
                200:"#141b2d"
            },
            start_date: {
                100:"#040509",
                200:"#d0d1d5"
            },
            end_date: {
                100:"#040509",
                200:"#d0d1d5"
            },
            id:{
                100:"#040509",
                200:"#d0d1d5"
            },
            customer:{
                100:"#040509",
                200:"#d0d1d5"
            },
            phone_number:{
                100:"#040509",
                200:"#d0d1d5"
            },
            menu:{
                100:"#040509",
                200:"#d0d1d5"
            },
            status:{
                100:"#040509",
                200:"#d0d1d5"
            },
            body:{
                100:"#F5F6FA",
            },
            card:{
              100:"#FFFFFF",
            },
            cardSubtitle:{
              100:"#636466",
            },
            cardTitle:{
              100:"#202224",
            },
            barchartBG:{
                100:"#FFFFFF"
            },
            barchartStroke:{
                100:"#e0e0e0"
            },
            transactionCost:{
                100:"#609966"
            },
            cardIcons:{
                100:"#f3f3f3",//expired
                200:"#e6f2ff",//processed
                300:"#fff3cd",//requests
                400:"#fcebed",//failed
            },
            cardIconsBG:{
                100:"#808080",//expired
                200:"#007bff",//processed
                300:"#ffc107",//requests
                400:"#dc3545",//failed
            },
            dote:"#212121",
            balanceBG:"#007bff",
            balance:"#efefef",
            add:"#2e7c67",
            addDialog:{
                100:"#9EC8F7",
                200:"#f2f0f0",
                300:"#040509",
                400:"#040509"
            },
            sidebarBG:{
                100:"#141b2d",
            },
            greeting:{
                100:"#abc5ff",
                200:"#185dfc"
            }
        }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    primary: {
                        main: colors.primary[500],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100]
                    },
                    background: {
                        default: colors.primary[500],
                    }

                } : {
                    primary: {
                        main: colors.primary[100],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100]
                    },
                    background: {
                        default: colors.body[100],
                    }
                }
            ),
        },
        typography: {
            fontFamily: ["Source Sans 4", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Source Sans 4", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};

// context for color mode
export const ColorModeContex = createContext({
    toogleColorMode: () => { }
});

export const useMode = () => {
    const [mode, setMode] = useState("light");

    const colorMode = useMemo(
        () => ({
            toogleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
}
