import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "az": "AZ",
                    "ru": "RU",
                    "en": "EN",
                    "logout": "Logout",
                    "requests": "Requests",
                    "processed": "Processed",
                    "failed": "Failed",
                    "expired": "Expired",
                    "customers_with_low_balance": "Customers with low balance",
                    "overloaded_gateways": "Overloaded gateways",
                    "results_by_months": "Results by months",
                    "january": "January",
                    "february": "February",
                    "march": "March",
                    "april": "April",
                    "may": "May",
                    "june": "June",
                    "july": "July",
                    "august": "August",
                    "september": "September",
                    "october": "October",
                    "november": "November",
                    "december": "December",
                    "balance": "Balance",
                    "dashboard": "Dashboard",
                    "send": "Send",
                    "send-otp": "Send OTP",
                    "reports": "Reports",
                    "users": "Users",
                    "creating": "Creating",
                    "menu": "Menu",
                    "gateways": "Gateways",
                    "send-otp-menu": "Send OTP Menu",
                    "token": "Token",
                    "textarea-message":"Enter numbers in international format, 1 per line. For example: 0501234567",
                    "required":"It is necessary",
                    "id":"ID",
                    "customer":"Customer",
                    "phone_number":"Phone Number",
                    "message":"Message",
                    "status":"Status",
                    "billing":"Billing",
                    "date":"Date",
                    "start_date":"Start date",
                    "end_date":"End date",
                    "columns":"Columns",
                    "filters":"Filters",
                    "density":"Density",
                    "export-file-name":"Export",
                    "record_count":"Record count",
                    "billed_amount":"Billed amount",
                    "on_dashboard":"Dashboard",
                    "list_request": "Requests list",
                    "list_users": "Users list",
                    "list_gateways": "Gateways list",
                    "send_otp": "Send OTP section",
                    "calls_count":"Calls",
                    "time":"Time",
                    "billed_amount_table":"Billed amount",
                    "reset":"Reset",
                    "all_customers":"All customers",
                    "processing": "Processing",
                    "processing-for-oneces": "Processing",
                    "all_gateways": "All gateways",
                    "name": "Name",
                    "type": "Type",
                    "isReseller": "Reseller",
                    "isActive": "Active",
                    "contact_person": "Contact person",
                    "role":"Role",
                    "all_name":"All names",
                    "all_role":"All roles",
                    "isPublic":"Public",
                    "regEx":"Regex",
                    "default_gateway":"Default gateway",
                    "comments":"Comments",
                    "native_name":"Native name",
                    "all_native_names":"All native names",
                    "all_prompts":"All sounds",
                    "code":"Code",
                    "caller_id_number":"Caller ID number",
                    "realm":"Realm",
                    "add":"Add",
                    "low_balance_amount":"Low balance amount",
                    "note":"Notes",
                    "address":"Address",
                    "active":"Active",
                    "add_customer":"Add customer",
                    "incorrect_phone_number":"Incorrect phone number",
                    "invalid_email":"Incorrect email address",
                    "close":"Close",
                    "save":"Save",
                    "create":"Create",
                    "add_user":"Add User",
                    "email":"Email",
                    "isAdmin":"Administrator",
                    "own_gateway_price":"Own gateway price",
                    "add_tariff":"Add Tariff",
                    "add_sender_name":"Add Sender Name",
                    "add_language":"Add Language",
                    "add_sip_profile":"Add Sip Profile",
                    "greeting":"Greeting",
                    "goodbye":"Goodbye",
                    "repeat":"Repeat",
                    "add_gateway":"Add gateway",
                    "username":"Username",
                    "password":"Password",
                    "transport":"Transport",
                    "channel_count":"Channel count",
                    "register":"Register",
                    "actions":"Actions",
                    "update_user":"Update user",
                    "update_gateway":"Update Gateway",
                    "view_user":"View user",
                    "view_gateway":"View gateway",
                    "api_key":"Api key",
                    "add_balance":"Add balance",
                    "add_api_key":"Add api key",
                    "balance_api_key_error_message":"At least one field must be filled: Balance or API Key",
                    "name_required_message":"This filed cannot be empty",
                    "add_item":"Add item",
                    "increment":"Increment",
                    "rate":"Rate",
                    "failed_rate":"Failed Rate",
                    "connection_price":"Connection price",
                    "add_submenu":"Add submenu",
                    "main_option":"Main Option: ",
                    "choose_file":"Select sound",
                    "max_size_limit_1":"The file size exceeds the maximum limit.",
                    "max_size_limit_2":" MB maximum limit.",
                    "max_size_limit_3": "The size of the file you selected is ",
                    "call-records":"Call records",
                    "record":"Record",
                    "delete_sender_name":"Are you sure you want to delete this sender name?",
                    "username_no_spaces": "Username cannot contain spaces",
                    "password_no_spaces": "Password cannot contain spaces",
                    "all_users":"All User",
                    "menu_name":"Menu name",
                    "add_submenu_to_submenu":"Add submenu to submenu",
                    "sub_option":"Submenu Option:",
                    "digits":"Digits",
                    "update":"Update",
                    "calling":"The call was opened",
                    "user_no_response":"User no response",
                    "no_user_response":"The call did not open",
                    "all_call_results":"All Call Statuses",
                    "user_busy":"The call did not open",
                    "question":"Question",
                    "invalid":"Invalid",
                    "hangup": "Hang up",
                    "try_count":"Number of wrong attempts",
                    "success": "Successful",
                    "error":"Failed",
                    "success_message_menu": "Menu successfully created",
                    "error_message_menu": "Error when creating menu: ",
                    "this_name_already_exits_try_another_one": "This name already exists try another one!",
                    "menu_saved_and_dialplan_generated_successfully": "Menu saved and dialplan generated successfully.",
                    "no_gateways_found":"No gateways found",
                    "number_one_menu":"Number one menu",
                    "try_count_error_lower_then_zero":"Number of wrong attempts cannot be lower then 0!",
                    "send_number_one":"Send Number One",
		            "deactivate_the_call":"Deactivate the call",
                    "created":"Created",
		            "are_you_sure_deactivate": "Are you sure want to deactivate the call?",
		            "alert":"Are you sure you want to change the call status?",
                    "success_alert":"Call status updated successfully!",
                    "bad_alert":"Failed to update call status.",
                    "loading_alert":"Updating call status...",
                    "call":"Repeat call",
                    "send-tts":"Send TTS",
                    "list_report_tts_avtomobil":"List of TTS Avtomobil",
                    "report_tts":"Report TTS",
                    "city_code":"City code",
                    "middle_code":"Middle code",
                    "second_middle_code":"Second middle code",
                    "last_three_number":"Last three number",
                    "duration":"Call duration",
                    "try_count_call":"Try Count",
                    "call_result":"Call Result",
                    "new_call_result":"New call result",
                    "from_at":"From At",
                    "to_at":"To At",
                    "menu_list":"Menu List",
                    "search":"Search",
                    "otp":"OTP",
                    "trans_id":"Transaction ID",
                    "otp_must_be_positive":"Otp must be positive",
                    "otp_must_be_4_digits":"Otp max 4 digits",
                    "otp_must_be_number":"Otp must be number",
                    "device_type":"Device Type",
                }
            },
            az: {
                translation: {
                    "az": "AZ",
                    "ru": "RU",
                    "en": "EN",
                    "logout": "Çıxış",
                    "requests": "Sorğular",
                    "processed": "Emal edildi",
                    "failed": "Uğursuz",
                    "expired": "Müddəti bitmiş",
                    "customers_with_low_balance": "Az balanslı müştərilər",
                    "overloaded_gateways": "Yüklənmiş gateway`lər",
                    "results_by_months": "Aylara görə nəticələr",
                    "january": "Yanvar",
                    "february": "Fevral",
                    "march": "Mart",
                    "april": "Aprel",
                    "may": "May",
                    "june": "İyun",
                    "july": "İyul",
                    "august": "Avqust",
                    "september": "Sentyabr",
                    "october": "Oktyabr",
                    "november": "Noyabr",
                    "december": "Dekabr",
                    "balance": "Balans",
                    "dashboard": "İdarə paneli",
                    "send": "Göndər",
                    "send-otp": "OTP göndərin",
                    "reports": "Hesabatlar",
                    "users": "İstifadəçilər",
                    "creating": "Yarat",
                    "menu": "Menyu",
                    "gateways": "Gateway`lər",
                    "send-otp-menu": "OTP menyusunu göndər",
                    "token": "Token",
                    "textarea-message":"Nömrələri beynəlxalq formatda, hər sətirdə 1 daxil edin. Məsələn: 0501234567",
                    "required":"Vacibdir",
                    "id":"ID",
                    "customer":"Müştəri",
                    "phone_number":"Telefon nömrəsi",
                    "message":"Mesaj",
                    "status":"Vəziyyət",
                    "billing":"Faktura",
                    "date":"Tarix",
                    "start_date":"Başlama tarixi",
                    "end_date":"Bitmə vaxtı",
                    "columns":"sütunlar",
                    "filters":"Filtrlər",
                    "density":"sıxlıq",
                    "export-file-name":"export-fayl-name",
                    "record_count":"Qeydlərin sayı",
                    "billed_amount":"Hesablanmış məbləğ",
                    "on_dashboard":"İdarə Paneli",
                    "list_request": "Sorğular siyahısı",
                    "list_users": "İstifadəçilər siyahısı",
                    "list_gateways": "Gateway`lər siyahısı",
                    "send_otp": "OTP göndərmə bölməsi",
                    "calls_count":"Zənglər",
                    "time":"Vaxt",
                    "billed_amount_table":"Hesablanmış məbləğ",
                    "reset":"Sıfırla",
                    "all_customers":"Bütün müştərilər",
                    "processing": "Emal edilir",
                    "processing-for-oneces": "Emal edilir",
                    "all_gateways": "Bütün gateway`lər",
                    "name": "Ad",
                    "type": "Növ",
                    "isReseller": "Reseller",
                    "isActive": "Aktiv",
                    "contact_person": "Əlaqədar şəxs",
                    "role":"Rol",
                    "all_name":"Bütün adlar",
                    "all_role":"Bütün rollar",
                    "isPublic":"İctimai",
                    "regEx":"Regex",
                    "default_gateway":"Defolt gateway",
                    "comments":"Rəylər",
                    "native_name":"Doğma ad",
                    "all_native_names":"Bütün yerli adlar",
                    "all_prompts":"Bütün səslər",
                    "code":"Kod",
                    "caler_id_number":"Zəng edənin ID nömrəsi",
                    "realm":"Realm",
                    "add":"Əlavə et",
                    "low_balance_amount":"Aşağı balans məbləği",
                    "note":"Qeydlər",
                    "address":"Ünvan",
                    "active":"Aktiv",
                    "add_customer":"Müştəri əlavə et",
                    "incorrect_phone_number":"Yanlış telefon nömrəsi",
                    "invalid_email":"Səhv e-poçt ünvanı",
                    "close":"Bağla",
                    "save":"Yadda saxla",
                    "create":"Yaratmaq",
                    "add_user":"İstifadəçi əlavə et",
                    "email":"E-poçt",
                    "isAdmin":"Administrator",
                    "own_gateway_price":"Öz gateway qiyməti",
                    "add_tariff":"Tarif əlavə et",
                    "add_sender_name":"Göndərən Adını əlavə et",
                    "add_language":"Dil əlavə et",
                    "add_sip_profile":"Sip Profili əlavə et",
                    "greeting":"Salam",
                    "goodbye":"Əlvida",
                    "repeat":"Təkrar et",
                    "add_gateway":"Gateway əlavə et",
                    "username":"İstifadəçi adı",
                    "password":"Parol",
                    "transport":"Nəqliyyat",
                    "channel_count":"Kanal sayı",
                    "register":"Qeydiyyatdan keçin",
                    "add_gateway_group":"Gateway qrupu əlavə et",
                    "actions":"Hərəkətlər",
                    "update_user":"İstifadəçini yeniləyin",
                    "update_gateway":"Gateway-i yeniləyin",
                    "view_user":"İstifadəçiyə bax",
                    "view_gateway":"Gateway`ə baxın",
                    "add_balance_or_api_key":"Balans və ya Api açarı əlavə edin",
                    "name_required_message":"Bu sahə boş ola bilməz",
                    "increment":"Artan",
                    "rate":"Derece",
                    "failed_rate":"Uğursuzluq dərəcəsi",
                    "connection_price":"Bağlantı qiyməti",
                    "add_submenu":"Alt menyu əlavə et",
                    "main_option":"Əsas seçim: ",
                    "choose_file":"Səs seçin",
                    "max_size_limit_1":"Fayl ölçüsü maksimum limiti keçir. ",
                    "max_size_limit_2":" MB maksimum limit.",
                    "max_size_limit_3": "Seçdiyiniz faylın ölçüsü ",
                    "call-records":"Zəng qeydləri",
                    "record":"Rekord",
                    "delete_sender_name":"Bu göndərənin adını silmək istədiyinizə əminsinizmi?",
                    "username_no_spaces": "İstifadəçi adında boşluq ola bilməz",
                    "password_no_spaces": "Parolda boşluq ola bilməz",
                    "all_users":"Bütün İstifadəçi",
                    "menu_name":"Menyu adı",
                    "add_submenu_to_submenu":"Alt menyuya alt menyu əlavə et",
                    "sub_option":"Altmenyu Seçimi:",
                    "digits":"Rəqəmlər",
                    "update":"Yeniləyin",
                    "calling":"Zəng açıldı",
                    "user_no_response":"İstifadəçi cavab vermir",
                    "no_user_response":"Zəng açılmadı",
                    "all_call_results":"Bütün Zəng Vəziyyətləri",
                    "user_busy":"Zəng açılmadı",
                    "question":"Sual",
                    "invalid":"Səhv",
                    "hangup":"Zəngi bitirmək",
                    "try_count":"Yanlış cəhdlərin sayı",
                    "success":"Uğurlu",
                    "error":"Uğursuz",
                    "success_message_menu":"Menyu uğurla yaradıldı",
                    "error_message_menu": "Menyu yaradarkən xəta: ",
                    "this_name_already_exits_try_another_one":"Bu ad artıq mövcuddur, başqasını sınayın!",
                    "menu_saved_and_dialplan_generated_successfully": "Menyu saxlanıldı və dialplan uğurla yaradıldı.",
                    "no_gateways_found":"Gateway tapılmadı",
                    "number_one_menu":"Nömrə bir menyu",
                    "try_count_error_lower_then_zero":"Yanlış cəhdlərin sayı 0-dan aşağı ola bilməz!",
                    "send_number_one":"Nömrə bir göndər",
		            "deactivate_the_call":"Zəngi söndür",
                    "created":"Yaradıldı",
		            "are_you_sure_deactivate": "Z?ngi deaktiv etm?k ist?diyiniz? ?minsinizmi?",
		            "alert":"Zəng statusunu dəyişmək istədiyinizə əminsinizmi?",
                    "success_alert":"Zəng statusu uğurla yeniləndi!",
                    "bad_alert":"Zəng statusunu yeniləmək alınmadı.",
                    "loading_alert":"Zəng statusu yenilənir...",
                    "call":"Tekrar zəng",
                    "send-tts":"TTS göndər",
                    "list_report_tts_avtomobil":"TTS Avtomobilin Siyahısı",
                    "report_tts":"TTS hesabatı",
                    "city_code":"Şəhər kodu",
                    "middle_code":"Orta kod",
                    "second_middle_code":"İkinci Orta Kod",
                    "last_three_number":"Son üç ədəd",
                    "try_count_call":"Cəhd sayı",
                    "duration":"Zəng müddəti",
                    "call_result":"Zəng Nəticəsi",
                    "new_call_result":"Yeni zəng nəticəsi",
                    "from_at":"Başlama tarixi",
                    "to_at":"Bitmə vaxtı",
                    "menu_list":"Menyu List",
                    "search":"Axtar",
                    "otp":"OTP",
                    "trans_id":"Əməliyyat ID-si",
                    "otp_must_be_positive":"Otp müsbət olmalıdır",
                    "otp_must_be_4_digits":"Otp maksimum 4 rəqəm",
                    "otp_must_be_number":"Otp nömrə olmalıdır",
                    "device_type":"Cihaz növü",
                    
                }
            },
            ru: {
                translation: {
                    "az": "АЗ",
                    "ru": "РУ",
                    "en": "ЕН",
                    "logout": "Выход",
                    "requests": "Запросы",
                    "processed": "Обработано",
                    "failed": "Неудачно",
                    "expired": "Истек",
                    "customers_with_low_balance": "Клиенты с низким балансом",
                    "overloaded_gateways": "Перегруженные шлюзы",
                    "results_by_months": "Результаты по месяцам",
                    "january": "Январь",
                    "february": "Февраль",
                    "march": "Март",
                    "april": "Апрель",
                    "may": "Май",
                    "june": "Июнь",
                    "july": "Июль",
                    "august": "Август",
                    "september": "Сентябрь",
                    "october": "Октябрь",
                    "november": "Ноябрь",
                    "december": "Декабрь",
                    "balance": "Баланс",
                    "dashboard": "Панель приборов",
                    "send": "Отправлять",
                    "send-otp": "Отправить OTP",
                    "reports": "Отчеты",
                    "users": "Пользователи",
                    "creating": "Создание",
                    "menu": "Меню",
                    "gateways": "Шлюзы",
                    "send-otp-menu": "Отправить меню OTP",
                    "token": "Токен",
                    "textarea-message":"Введите числа в международном формате, по 1 в строке. Например: 0501234567.",
                    "required":"Это необходимо",
                    "id":"ID",
                    "customer":"Клиент",
                    "phone_number":"Номер телефона",
                    "message":"Сообщение",
                    "status":"Состояние",
                    "billing":"Выставление счетов",
                    "date":"Период",
                    "start_date":"Дата начала",
                    "end_date":"Дата окончания",
                    "columns":"столбцы",
                    "filters":"Фильтры",
                    "density":"плотность",
                    "export-file-name":"имя-файла-экспорта",
                    "record_count":"Количество записей",
                    "billed_amount":"Сумма к оплате",
                    "on_dashboard":"Панели управления",
                    "list_request": "Списк запросов",
                    "list_users": "Списк пользователей",
                    "list_gateways": "Списк шлюзов",
                    "send_otp": "Отправке OTP",
                    "calls_count":"Звонки",
                    "time":"Время",
                    "billed_amount_table":"Сумма счета",
                    "reset":"Вправлять",
                    "all_customers":"Все клиенты",
                    "all_gateways":"Все шлюзы",
                    "processing": "Обработка",
                    "processing-for-oneces": "Обработка",
                    "name": "Имя",
                    "type": "Тип",
                    "isReseller": "Реселлер",
                    "isActive": "Активен",
                    "contact_person": "Контактное лицо",
                    "role":"Роль",
                    "all_name":"Все имена",
                    "all_role":"Все роли",
                    "isPublic":"Публичный",
                    "regEx":"Регулярное выражение",
                    "default_gateway":"Шлюз по умолчанию",
                    "comments":"Комментарии",
                    "native_name":"Родное имя",
                    "all_native_names":"Все местные имена",
                    "all_prompts":"Все звуки",
                    "code":"Код",
                    "caller_id_number":"Номер ID вызывающего абонента",
                    "realm":"Realm",
                    "add":"Добавлять",
                    "low_balance_amount":"Низкая сумма баланса",
                    "note":"Примечания",
                    "address":"Адрес",
                    "active":"Активный",
                    "incorrect_phone_number":"Неправильный номер телефона",
                    "invalid_email":"Неверный адрес электронной почты",
                    "close":"Закрыть",
                    "save":"Сохранять",
                    "create":"Создавать",
                    "add_user":"Добавить пользователя",
                    "email":"Электронная почта",
                    "isAdmin":"Администратор",
                    "own_gateway_price":"Цена собственного шлюза",
                    "greeting":"Приветствие",
                    "goodbye":"До свидания",
                    "repeat":"Повторить",
                    "add_gateway":"Добавить шлюз",
                    "username":"Имя пользователя",
                    "password":"Пароль",
                    "transport":"Транспорт",
                    "channel_count":"Количество каналов",
                    "register":"Регистрация",
                    "actions":"Действия",
                    "update_user":"Обновить пользователя",
                    "update_gateway":"Обновить шлюз",
                    "view_user":"Просмотреть пользователя",
                    "view_gateway":"Посмотреть шлюз",
                    "add_balance_or_api_key":"Добавить баланс или ключ API",
                    "тариф":"Тариф",
                    "api_key":"API-ключ",
                    "add_balance":"Добавить баланс",
                    "add_api_key":"Добавить ключ API",
                    "balance_api_key_error_message":"Необходимо заполнить хотя бы одно поле: Баланс или API-ключ",
                    "name_required_message":"Это поле не может быть пустым",
                    "add_item":"Добавить элемент",
                    "increment":"Increment",
                    "rate":"Оценка",
                    "failed_rate":"Процент неудачных попыток",
                    "connection_price":"Цена соединения",
                    "add_submenu":"Добавить подменю",
                    "main_option":"Основной вариант: ",
                    "choose_file":"Выбрать звук",
                    "max_size_limit_1":"Размер файла превышает максимально допустимый лимит. ",
                    "max_size_limit_2":"Максимальный лимит.",
                    "max_size_limit_3": "Размер выбранного вами файла ",
                    "call-records":"Записи звонков",
                    "record":"Запись",
                    "delete_sender_name":"Вы уверены, что хотите удалить это имя отправителя?",
                    "username_no_spaces": "Имя пользователя не может содержать пробелов",
                    "password_no_spaces": "Пароль не может содержать пробелы",
                    "all_users":"Все пользователи",
                    "menu_name":"Название меню",
                    "add_submenu_to_submenu":"Добавить подменю в подменю",
                    "sub_option":"Вариант подменю:",
                    "digits":"Цифры",
                    "update":"Обновлять",
                    "calling":"Звонок открыт",
                    "user_no_response":"Пользователь не ответил",
                    "no_user_response":"Звонок не открылся",
                    "all_call_results":"Все статусы вызовов",
                    "user_busy":"Звонок не открылся",
                    "question":"Вопрос",
                    "invalid":"Неправильный",
                    "hangup": "Повесить трубку",
                    "try_count":"Количество неправильных попыток",
                    "success": "Успешный",
                    "error":"Не удалось",
                    "success_message_menu": "Меню успешно создано",
                    "error_message_menu": "Ошибка создания меню: ",
                    "this_name_already_exits_try_another_one":"Такое имя уже существует, попробуйте другое!",
                    "menu_saved_and_dialplan_generated_successfully": "Меню сохранено и план набора успешно создан.",
                    "no_gateways_found":"Шлюзы не найдены",
                    "number_one_menu":"Меню номер один",
                    "try_count_error_lower_then_zero":"Количество неправильных попыток не может быть меньше 0!",
                    "send_number_one":"Отправить номер один",
		            "deactivate_the_call":"Деактивировать вызов",
		            "created":"Создано",
		            "are_you_sure_deactivate": "Вы уверены, что хотите деактивировать вызов?",
		            "alert":"Вы уверены, что хотите изменить статус вызова?",
                    "success_alert":"Статус вызова успешно обновлен!",
                    "bad_alert":"Не удалось обновить статус вызова.",
                    "loading_alert":"Обновление статуса вызова...",
                    "call":"Повторный вызов",
                    "send-tts":"Отправить TTS",
                    "list_report_tts_avtomobil":"Список ТТС Автомобили",
                    "report_tts":"Отчет о TTS",
                    "city_code":"Код города",
                    "middle_code":"Средний код",
                    "second_middle_code":"Второй средний код",
                    "last_three_number":"Последние три цифры",
                    "try_count_call": "Количество попыток",
                    "duration":"Продолжительность звонка",
                    "call_result":"Результат вызова",
                    "new_call_result":"Результат нового звонка",
                    "from_at":"Дата начала",
                    "to_at":"Время окончания",
                    "menu_list":"Список меню",
                    "search":"Поиск",
                    "otp":"OTP",
                    "trans_id":"Идентификатор транзакции",
                    "otp_must_be_positive":"Otp должен быть положительным",
                    "otp_must_be_4_digits":"Otp макс. 4 цифры",
                    "otp_must_be_number":"Otp должен быть числом",
                    "device_type":"Тип устройства",
                }
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
