import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";


const useStyles = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return {
        submenu:{
            firstBox: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            },
            secondBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: colors.card[100],
                width: "100%",
                height: "750px",
                padding: "20px",
            },
            submenuBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
            },
            thirdBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
            },
            buttonClose: {
                height: "50px",
                lineHeight: "20px",
                backgroundColor: colors.redAccent[1000],
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: colors.redAccent[1100],
                    color: "#FFFFFF",
                },
            },
            fourthBox: {
                display: "flex",
                alignItems: "center",
            },
            headInput: {
                backgroundColor: colors.greeting[100],
                color: colors.greeting[200],
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "150px",
                borderRadius: "8px",
                marginLeft: "5px",
                justifyContent: "center",
            },
            bodyInput: {
                backgroundColor: "#FFFFFF",
                color: colors.greeting[200],
                height: "70px",
                width: "70px",
                display: "flex",
                alignItems: "center",
                borderRadius: "50px",
                marginLeft: "5px",
                justifyContent: "center",
                marginTop:"5px"
            },
            inputHidden: {
                display: "none",
            },
            bodyLabel: {
                fontSize: "28px",
                color: "black",
                cursor: "pointer",
            },
            fifthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "25px",
                justifyContent: "space-between",
                gap: "40px",
            },
            sixthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            seventhBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            eighthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
        },
        mainMenu:{
            firstBox: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            },
            secondBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: colors.card[100],
                width: "100%",
                height:"770px",
                padding: "20px",
                maxHeight:"790px",
            },
            thirdBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "120px",
            },
            buttonAddSubmenu: {
                height: "50px",
                lineHeight: "20px",
                backgroundColor: colors.add,
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: colors.add,
                    color: "#FFFFFF",
                }
            },
            fourthBox: {
                display: "flex",
                alignItems: "center",
            },
            headInput: {
                backgroundColor: colors.greeting[100],
                color: colors.greeting[200],
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "150px",
                borderRadius: "8px",
                marginLeft: "5px",
                justifyContent: "center",
            },
            saveButton:{
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "150px",
                borderRadius: "8px",
                marginLeft: "5px",
                justifyContent: "center",
                lineHeight: "20px",
                backgroundColor: colors.add,
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: colors.add,
                    color: "#FFFFFF",
                }
            },
            bodyInput: {
                backgroundColor: colors.grey[900],
                color: colors.greeting[200],
                height: "70px",
                width: "70px",
                display: "flex",
                alignItems: "center",
                borderRadius: "50px",
                marginLeft: "5px",
                justifyContent: "center",
                "&:hover": {
                    borderRadius: "50px",
                },
            },
            inputHidden: {
                display: "none",
            },
            buttonFonSize: {
                backgroundColor:"#FFFFFF",
                borderRadius:"50px",
                fontSize: "28px",
                "&:hover": {
                    borderRadius: "50px",
                },
            },
            bodyLabel: {
                fontSize: "28px",
                color: "black",
                cursor: "pointer",
            },
            fifthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "50px",
                justifyContent: "space-between",
                gap: "40px",
            },
            sixthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            seventhBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            eighthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            saveButtonBox:{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                marginTop: "9%",
                marginLeft:"90%",
            }
        },

        mainMenu2:{
            firstBox: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            },
            secondBox: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: colors.card[100],
                width: "100%",
                height:"590px",
                padding: "20px",
                maxHeight:"590px",
            },
            thirdBox: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "40px",
            },
            buttonAddSubmenu: {
                height: "50px",
                lineHeight: "20px",
                backgroundColor: colors.add,
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: colors.add,
                    color: "#FFFFFF",
                }
            },
            fourthBox: {
                display: "flex",
                alignItems: "center",
            },
            headInput: {
                backgroundColor: colors.greeting[100],
                color: colors.greeting[200],
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "150px",
                borderRadius: "8px",
                marginLeft: "5px",
                justifyContent: "center",
            },
            saveButton:{
                height: "50px",
                display: "flex",
                alignItems: "center",
                width: "150px",
                borderRadius: "8px",
                marginLeft: "5px",
                justifyContent: "center",
                lineHeight: "20px",
                backgroundColor: colors.add,
                color: "#FFFFFF",
                "&:hover": {
                    backgroundColor: colors.add,
                    color: "#FFFFFF",
                }
            },
            bodyInput: {
                backgroundColor: colors.grey[900],
                color: colors.greeting[200],
                height: "70px",
                width: "70px",
                display: "flex",
                alignItems: "center",
                borderRadius: "50px",
                marginLeft: "5px",
                justifyContent: "center",
                "&:hover": {
                    borderRadius: "50px",
                },
            },
            inputHidden: {
                display: "none",
            },
            buttonFonSize: {
                fontSize: "28px",
                "&:hover": {
                    borderRadius: "50px",
                },
            },
            bodyLabel: {
                fontSize: "28px",
                color: "black",
                cursor: "pointer",
            },
            fifthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "50px",
                justifyContent: "space-between",
                gap: "40px",
            },
            sixthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            seventhBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            eighthBox: {
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                gap: "40px",
            },
            saveButtonBox:{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                marginTop: "12%",
                marginLeft:"90%",
            }
        },

    };
};

export default useStyles;
