import React, {useMemo} from "react";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {Routes, Route, useLocation} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {ColorModeContex, useMode} from "./theme";
import {AuthProvider} from "../src/scenes/auth/AuthContext";
import PrivateRoute from "../src/routes/PrivateRoute";
import Dashboard from "./scenes/dashboard";
import TopBar from "./scenes/global/TopBar";
import Sidebar from "./scenes/global/Sidebar";
import SendOTP from "./scenes/api-send-otp";
import Users from "./scenes/user";
import Gateway from "./scenes/gateway";
import Requests from "./scenes/requests";
import LoginForm from "./scenes/auth/login";
import Logout from "./scenes/auth/logout";


const queryClient = new QueryClient();

function App() {
    const [theme, colorMode] = useMode();
    const location = useLocation();
    const {t} = useTranslation();

    const pageNames = useMemo(() => ({
        "/": t('on_dashboard'),
        "/call-requests": t('list_request'),
        "/otp-send": t('send_otp'),
        "/users": t('list_users'),
        "/gateways": t('list_gateways'),
    }), [t]);

    return (
        <AuthProvider>
            <Routes>
                <Route path="/login" element={<LoginForm/>}/>
                <Route path="/logout" element={<Logout/>}/>
                <Route
                    path="*"
                    element={
                        <ColorModeContex.Provider value={colorMode}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline/>
                                <QueryClientProvider client={queryClient}>
                                    <div className="app">
                                        <Sidebar/>
                                        <main className="content">
                                            <TopBar page_name={pageNames[location.pathname]}/>
                                            <div className="main-content">
                                                <Routes>
                                                    <Route path="/" element={<PrivateRoute element={<Dashboard/>}/>}/>
                                                    <Route path="/call-requests" element={<PrivateRoute element={<Requests/>}/>}/>
                                                    <Route path="/otp-send" element={<PrivateRoute element={<SendOTP/>}/>}/>
                                                    <Route path="/users" element={<PrivateRoute element={<Users/>}/>}/>
                                                    <Route path="/gateways" element={<PrivateRoute element={<Gateway/>}/>}/>
                                                </Routes>
                                            </div>
                                        </main>
                                    </div>
                                </QueryClientProvider>
                            </ThemeProvider>
                        </ColorModeContex.Provider>
                    }
                />
            </Routes>
        </AuthProvider>
    );
}

export default App;
