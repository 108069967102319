import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const AlertSuccess = ({ title, message }) => {
    return (
        <Alert sx={{maxWidth:"500px"}} severity="success">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
};

export const AlertError = ({ title, message }) => {
    return (
        <Alert sx={{maxWidth:"500px"}} severity="error">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
};
