import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import BarChart from "../../components/BarChart";
import StateBox from "../../components/StatBox";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CallEndIcon from '@mui/icons-material/CallEnd';
import {useEffect, useState} from "react";
import Cookie from "js-cookie";
import axios from "axios";
import {jwtDecode} from "jwt-decode";

const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
};

const Dashboard = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const stateBoxs = useMediaQuery("(max-width:1280px)");
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_CALL_RESULTS_API_ENDPOINT;
    const token = Cookie.get("token");
    const decodedToken = jwtDecode(token);

    useEffect(() => {
        const fetchData = async () => {
            const token = Cookie.get("token");
            try {
                const response = await axios.post(apiEndpoint,
                    {
                        id: decodedToken.user_id
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                setData(response.data);
            } catch (error) {
            }
        };
        fetchData();
    }, []);

    const defaultStats = data.reduce((acc, item) => {
        if (item.title === 'requests') {
            acc.requests = item.count;
        } else if (item.title === 'processed') {
            acc.processed = item.count;
        } else if (item.title === 'expired') {
            acc.expired = item.count;
        } else if (item.title === 'failed') {
            acc.failed = item.count;
        }
        return acc;
    }, {
        requests: 0,
        processed: 0,
        failed: 0,
        expired: 0
    });

    return (
        <Box m="20px" sx={{
            "& ::-webkit-scrollbar-thumb": {
                backgroundColor: colors.balanceBG,
                borderRadius: "4px",
            },
            "& ::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
            },
            "& ::-webkit-scrollbar": {
                width: "5px",
                height: "5px"
            },
        }}
        >
            <Box className="second-Box" display="grid"
                 gridTemplateColumns={isNonMobile ? "repeat(12, 1fr)" : "repeat(6, 1fr)"}
                 gridAutoRows="150px"
                 gap="15px"
            >
                {/* ROW 1 */}
                <Box gridColumn={isNonMobile ? "span 4" : "span 7"} backgroundColor={colors.card[100]} display="flex"
                     alignItems="center"
                     justifyContent="center" borderRadius="8px">

                    <StateBox title={formatNumber(defaultStats.requests)} subtitle={t('requests')}
                              icon={<Box sx={{ backgroundColor: colors.cardIcons[300], display: "flex",
                                  justifyContent: "center", alignItems: "center", borderRadius: stateBoxs ? "16px" : "23px", height: stateBoxs ? "50px" : "90px",
                                  width: stateBoxs ? "50px" : "90px"
                              }}><PhoneForwardedIcon sx={{ color: colors.cardIconsBG[300], fontSize: stateBoxs ? "25px" : "35px" }} /></Box>} />
                </Box>

                <Box gridColumn={isNonMobile ? "span 4" : "span 7"} backgroundColor={colors.card[100]} display="flex"
                     alignItems="center"
                     justifyContent="center" borderRadius="8px">

                    <StateBox title={formatNumber(defaultStats.processed)} subtitle={t('processed')}
                              icon={<Box sx={{ backgroundColor: colors.cardIcons[200], display: "flex",
                                  justifyContent: "center", alignItems: "center", borderRadius: stateBoxs ? "16px" : "23px", height: stateBoxs ? "50px" : "90px",
                                  width: stateBoxs ? "50px" : "90px"
                              }}><SettingsPhoneIcon sx={{ color: colors.cardIconsBG[200], fontSize: stateBoxs ? "25px" : "35px" }} /></Box>} />
                </Box>

                <Box gridColumn={isNonMobile ? "span 4" : "span 7"} backgroundColor={colors.card[100]} display="flex"
                     alignItems="center"
                     justifyContent="center" borderRadius="8px">

                    <StateBox title={formatNumber(defaultStats.failed)} subtitle={t('failed')}
                              icon={<Box sx={{ backgroundColor: colors.cardIcons[400], display: "flex",
                                  justifyContent: "center", alignItems: "center", borderRadius: stateBoxs ? "16px" : "23px", height: stateBoxs ? "50px" : "90px",
                                  width: stateBoxs ? "50px" : "90px"
                              }}><PhoneDisabledIcon sx={{ color: colors.cardIconsBG[400], fontSize: stateBoxs ? "25px" : "35px" }} /></Box>} />
                </Box>
                <Box
                    gridColumn="span 12"
                    gridRow="span 2"
                    backgroundColor={colors.barchartBG[100]}
                    sx={{ marginTop: "10px", borderRadius: "8px", border: "0.5px solid " + colors.barchartStroke[100] }}
                >
                    <Box
                        mt="25px"
                        padding="0 30px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"

                    >
                        <Box>
                            <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                                {t('results_by_months')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box height="300px" mt="-40px" ml="20px">
                        <BarChart isDashboard={true} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
